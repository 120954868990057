<template>
	<div class="vld-parent">
		<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-m-1">
						<label for="inmueble">Inmueble</label>
						<div class="p-inputgroup">
							<InputText size="135" id="inmueble" type="text" v-model="contador.inmueble" disabled/>																							
							<Button icon="pi pi-search" @click="seleccionarInmueble" />							
						</div>
					</div>
                    <div class="p-field p-col">
                        <label class="p-col" for="activo">Activo</label>
                        <InputSwitch :disabled="deshabilitado" v-model="contador.activoDisplay"/>
                    </div>
				</div>				
				<div class="p-field">
					<label for="id_contador_tipo">Tipo</label>
					<Dropdown v-model="contador.id_contador_tipo" required="true" :options="lkpContadorTipos" optionLabel="contador_tipo" optionValue="id_contador_tipo" placeholder="Seleccionar..." />
				</div>
				<div class="p-field">
					<label for="id_contador_tarifa">Tarifa</label>
					<Dropdown  v-model="contador.id_contador_tarifa" required="true" :options="lkpTarifas" optionLabel="tarifa" optionValue="id_contador_tarifa" placeholder="Seleccionar..." />
				</div>
				<div class="p-field">
					<label for="numero_contador">Número</label>
                    <InputText :disabled="deshabilitado" v-model="contador.numero_contador" autofocus />                    				
				</div>
				<div class="p-field">
					<label for="observaciones">Observaciones</label>
                    <InputText v-model="contador.observaciones" autofocus />                    				
				</div>				
				<div class="p-field">
					<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="save"/>				
				</div>
			</div>
		</div>
		<MdsBuscarInmueble :mostrar="inmuebleDialog" @cancelar="cerrarDlgInmueble" @seleccionar="elegirInmueble"/>
	</div>
</template>

<script>
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import MdsService from '../../service/mds.service';
import MdsBuscarInmueble from '../../components/MdsBuscarInmueble.vue';

export default {
	data() {
		return {
			loading: false,
            lkpTarifas: this.$store.state.auth.currentAppCtx.contadores_tarifas,
            lkpContadorTipos: this.$store.state.auth.currentAppCtx.contadores_tipo, 
			inmuebleDialog: false,
			InmuebleSel: null,
			contador: {
				id_inmueble: '',
				inmueble: '',
                id_contador: '',
				numero_contador: '',
                utiliza_tarifa_escalonada: 'N',
				observaciones: '',
                id_contador_tipo: 1,
                id_contador_tarifa: 0,
				activo: 'S',
				activoDisplay: true 
			},
		}
	},
	components: {
		Loading, MdsBuscarInmueble
	},
	props: {
		id: {
			type: Number,
			required: true,
			default: ''
			}			
	},		
	created(){
		if(this.id != "")
		{
			this.fetchData();
		} else {
			this.openNew();
		}
	},
	methods: {
		openNew() {
			this.contador = {
				id_inmueble: "",
				inmueble: "Seleccionar...",
                id_contador: 0,
				numero_contador: "",
                utiliza_tarifa_escalonada: "N",
				observaciones: "",
                id_contador_tipo: 1,
                id_contador_tarifa: 0,
				activo: "S",
				activoDisplay: true
			}; 
		},		
		async save() {						
			try {
				this.contador.activo = this.contador.activoDisplay ? 'S': 'N';
				this.loading = true;
				var data = await MdsService.registrarContador(this.$store.state.auth.currentAppCtx, this.contador);
				this.contador.id_contador = data.id_contador;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'Los cambios han sido guardados con éxito!', life: 3000});									
				this.loading = false;				
			} catch (err) {
				this.loading = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
		},
		fetchData () {
			this.error = this.post = null;
			this.loading = true;

            let filtro = {
                id: this.id,
                id_complejo: this.$store.state.auth.currentAppCtx.complejo.id_complejo,
                id_inmueble: ''
            };

			MdsService.getContadores(this.$store.state.auth.currentAppCtx, filtro).then(data => {				
				this.loading = false;
				this.contador = data;
				this.contador.activoDisplay = (data.activo == 'S');
			}
			).catch(
				data => {
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			)
		},
		seleccionarInmueble() {
			this.inmuebleDialog = true;
		},
		cerrarDlgInmueble(){
			this.inmuebleDialog = false;
		},					
		elegirInmueble(Inmueble){
			this.inmuebleDialog = false;
			this.InmuebleSel = Inmueble;		
			this.contador.id_inmueble = Inmueble.id_inmueble;
			this.contador.inmueble = Inmueble.display;
		},					
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
